import React, { Fragment } from 'react'
import { useApi } from '~/api'
import * as st from '~/assets/styl/ContactsInfos.module.styl'

const googleMapsUrl = 'http://www.google.com/maps/place'
const whatsUrl = 'https://api.whatsapp.com/send'
// 'https://www.google.com/maps/place/Bauer+Turismo/@-27.0735648,-52.6226666,15z/data=!4m2!3m1!1s0x0:0x38f7080df88f3a84?sa=X&ved=2ahUKEwitwIH8lOf3AhWcu5UCHcByCScQ_BJ6BAhOEAU'

export default function ContactsInfos() {
  const config = useApi({}, 'configurations')
  const addresses = useApi([], 'addresses')
  const whatsapps = useApi([], 'whatsapps')

  return (
    config &&
    addresses &&
    whatsapps && (
      <div className={st.infosContainer}>
        <div className={st.infosWrapper}>
          {Boolean(addresses && addresses.length) && (
            <section>
              <h2>Endereço</h2>
              {addresses.map((address, key) => (
                <Fragment key={key}>
                  <address key={key}>
                    {address.address}
                    {address.neighborhood ? `, ${address.neighborhood}` : ''}
                    <br />
                    {`${address.city} - ${address.uf}`}
                  </address>
                  {Boolean(address.latitude && address.longitude) && (
                    <a
                      href={`${googleMapsUrl}/${address.latitude},${address.longitude}`}
                      target="_blank"
                      className={st.googleLink}
                      rel="noreferrer external nofollow"
                    >
                      Abrir no Google Maps
                    </a>
                  )}
                </Fragment>
              ))}
            </section>
          )}
          {Boolean(config.phone) && (
            <section className={st.phone}>
              <h2>Telefone</h2>
              <span>{config.phone}</span>
            </section>
          )}
          {Boolean(whatsapps && whatsapps.length) && (
            <section>
              <h2>WhatsApp</h2>
              {whatsapps.map((item, key) => (
                <a
                  href={`${whatsUrl}?phone=55${item.phone.replace(/\D/g, '')}`}
                  target="_blank"
                  rel="noreferrer external nofollow"
                  key={key}
                >
                  <div className={st.whats}>
                    <span className="icon-whatsapp"></span>
                    <p>{item.phone}</p>
                  </div>
                </a>
              ))}
            </section>
          )}
        </div>
      </div>
    )
  )
}
