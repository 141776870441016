import React, { useState, useRef } from 'react'
import { Link, navigate } from 'gatsby'

import * as st from '~/assets/styl/ContactForm.module.styl'
import _InputMask from 'react-input-mask'

import api, { useApi } from '~/api'
import ReCAPTCHA from 'react-google-recaptcha'
import formatPhone from '~/utils/formatPhone'

type RecaptchaProps = {
  className?: string
  inputRef: React.MutableRefObject<ReCAPTCHA>
}

export const Recaptcha = ({ inputRef: ref, className }: RecaptchaProps) => (
  <ReCAPTCHA
    ref={ref}
    sitekey="6LfQlx8gAAAAAHCnHDrGOmfl3iktSLsBkjei9Lbx"
    size="invisible"
    className={className}
  />
)

export const InputMask = ({
  mask,
  name,
  required = true,
  value,
  onChange,
  placeholder,
  ...props
}: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { mask: string }) =>
  placeholder && (
    <_InputMask mask={mask} maskChar={null} value={value} onChange={onChange}>
      {(inputProps) => (
        <input
          name={name}
          placeholder={placeholder}
          required={required}
          {...inputProps}
          {...props}
        />
      )}
    </_InputMask>
  )

export default function ContactForm() {
  const recaptchaRef = useRef<ReCAPTCHA>()
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    subject: '',
    message: '',
    privacy: false,
  })
  const [error, setError] = useState('')

  const subjects = useApi(null, 'subjects')

  const success = () => {
    navigate('/obrigado/')
  }

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  function handleSubmit(recaptcha: string) {
    api(
      'post',
      'leads/contact',
      { ...data, recaptcha },
      { headers: { 'Content-Type': 'text/plain' } }
    ).then((data) => {
      if (data.code === 0) {
        success()
      } else {
        recaptchaRef.current.reset()
        let error = ''
        for (const key in data.errors) {
          error = data.errors[key]
          break
        }
        setError(error)
      }
    })
  }

  return (
    <section className={st.contactContainer}>
      <h2>Fale conosco</h2>
      <div className={st.contactWrapper}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            recaptchaRef.current
              .executeAsync()
              .then((value) => handleSubmit(value))
          }}
        >
          <div className={st.formWrapper}>
            <label>
              <input
                type="text"
                placeholder="Seu nome"
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                required
              />
            </label>
            <label>
              <input
                type="email"
                placeholder="Seu e-mail"
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                required
              />
            </label>
          </div>
          <div className={st.formWrapper}>
            <label>
              <InputMask
                mask={
                  data.phone.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999'
                }
                value={data.phone}
                onChange={(e) => setData({ ...data, phone: e.target.value })}
                name="phone"
                placeholder="Telefone"
                type="tel"
                pattern=".{14,15}"
              />
            </label>
            <label>
              <input
                type="text"
                placeholder="Empresa"
                value={data.company}
                onChange={(e) => setData({ ...data, company: e.target.value })}
              />
            </label>
          </div>
          <div className={st.formWrapper}>
            <label className={st.formSelect}>
              <select
                name="subject"
                id="subject"
                onChange={onChange}
                value={data.subject}
              >
                <option disabled value="">
                  Assunto
                </option>
                {Boolean(subjects && subjects.length) &&
                  subjects.map((subject, key) => (
                    <option key={key} value={subject.id}>
                      {subject.name}
                    </option>
                  ))}
              </select>
            </label>
          </div>
          <div className={st.formWrapper}>
            <label>
              <textarea
                name="message"
                placeholder="Mensagem"
                id="message"
                cols={30}
                rows={10}
                onChange={(e) => setData({ ...data, message: e.target.value })}
                required
              />
            </label>
          </div>
          <div className={st.privacy}>
            <label>
              <input
                id="use_terms"
                type="checkbox"
                name="privacy"
                onChange={(e) => setData({ ...data, privacy: e.target.value })}
                required
              />
              <small>
                Declaro que li e aceito a{' '}
                <Link to="/politica-de-privacidade">
                  Política de privacidade
                </Link>
              </small>
            </label>
          </div>
          {error && <div>{error}</div>}
          <Recaptcha inputRef={recaptchaRef} />
          <button type="submit">Enviar</button>
        </form>
      </div>
    </section>
  )
}
