import React from 'react'
import PropTypes from 'prop-types'

import * as st from '~/assets/styl/Banner.module.styl'

export default function Banner(props) {
  const {
    bannerBackgroundColor,
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    bannerImageAlt,
    imageBottomPosition,
  } = props

  return (
    <div className={st.bannerContainer}>
      <section
        className={st.bannerSection}
        style={{
          backgroundColor: bannerBackgroundColor || '#fff',
        }}
      >
        <div className={st.bannerDescription}>
          <h2>{bannerTitle}</h2>
          {bannerSubtitle && <p>{bannerSubtitle}</p>}
        </div>
        <img
          src={bannerImage}
          alt={bannerImageAlt}
          style={{ bottom: imageBottomPosition || 0 }}
        />
      </section>
    </div>
  )
}

Banner.propTypes = {
  bannerBackgroundColor: PropTypes.string,
  bannerTitle: PropTypes.string,
  bannerSubtitle: PropTypes.string,
  bannerImage: PropTypes.string,
  bannerImageAlt: PropTypes.string,
  imageBottomPosition: PropTypes.number,
}
