import React from 'react'
import { Head } from '~/components'
import Menu from '~/components/Menu'
import Breadcrumb from '~/components/Breadcrumb'
import Banner from '~/components/Banner'
import Footer from '~/components/Footer'

import bannerContato from '~/assets/img/banner-contato.png'
import ContactForm from '~/components/ContactForm'
import ContactsInfos from '~/components/ContactsInfos'

const Contato = () => (
  <>
    <Head title={'Contato - ' + process.env.GATSBY_SITE_NAME} />
    <Banner
      bannerBackgroundColor="#BCBCBC"
      bannerTitle="Entre em Contato e fale com nossa equipe"
      bannerImage={bannerContato}
      bannerImageAlt="Foto de uma pessoa mexendo no notebook com o logo da Bauer ao lado"
      imageBottomPosition={15}
    />
    <Breadcrumb />
    <ContactForm />
    <ContactsInfos />
    <Footer />
  </>
)

export default Contato
